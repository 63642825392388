











































































































































































































import { Row } from "@/hooks";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { REPORT_AGING_STATUS } from "@/models/enums/report-aging.enum";
import {
  DataReportAging,
  GetAgingReport,
} from "@/models/interface/aging.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { agingServices } from "@/services/aging.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

type TableRow = Row<DataReportAging>;

@Component
export default class ReportAging extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  dataListBranch: DataWarehouseBranch[] = [];
  totalElements = 0;
  dataSource: TableRow[] = [];
  dataListCustomer: ContactData[] = [];
  dataListCurrency: DataMasterCurrency[] = [];
  loading = {
    find: false,
    branch: false,
    customer: false,
    currency: false,
    download: false,
  };
  downloadParams = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    customer: "ALL",
    currency: "ALL",
    date: "ALL",
    search: "",
  };
  columnsTable = [
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
      key: "customerCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      key: "total",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_current"),
      dataIndex: "current",
      key: "current",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_1_to_30_days"),
      dataIndex: "outstanding1",
      key: "1-30days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_31_to_60_days"),
      dataIndex: "outstanding2",
      key: "31-60days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_61_to_90_days"),
      dataIndex: "outstanding3",
      key: "61-90days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_91_to_120_days"),
      dataIndex: "outstanding4",
      key: "91-120days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_121_to_150_days"),
      dataIndex: "outstanding5",
      key: "121-150days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_151_to_180_days"),
      dataIndex: "outstanding6",
      key: "151-180days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_181_to_210_days"),
      dataIndex: "outstanding7",
      key: "181-210days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_211_to_240_days"),
      dataIndex: "outstanding8",
      key: "211-240days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_241_to_270_days"),
      dataIndex: "outstanding9",
      key: "241-270days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_271_to_300_days"),
      dataIndex: "outstanding10",
      key: "271-300days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_301_to_330_days"),
      dataIndex: "outstanding11",
      key: "301-330days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_331_to_360_days"),
      dataIndex: "outstanding12",
      key: "331-360days",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_more_360_days"),
      dataIndex: "outstanding13",
      key: ">360days",
      scopedSlots: { customRender: "currency" },
    },
  ];
  form!: WrappedFormUtils;
  formRules = {
    branch: {
      label: "lbl_branch",
      name: "lbl_branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    asOfDate: {
      label: "lbl_as_of_date",
      name: "asOfDate",
      placeholder: "lbl_as_of_date",
      decorator: [
        "asOfDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  dataReport: GetAgingReport = {
    reportAgingListDTOList: [],
    totalOutstanding1: 0,
    totalOutstanding2: 0,
    totalOutstanding3: 0,
    totalOutstanding4: 0,
    totalOutstanding5: 0,
    totalOutstanding6: 0,
    totalOutstanding7: 0,
    totalOutstanding8: 0,
    totalOutstanding9: 0,
    totalOutstanding10: 0,
    totalOutstanding11: 0,
    totalOutstanding12: 0,
    totalOutstanding13: 0,
    grandTotal: 0,
    totalCurrent: 0,
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "agingPiutang" });
  }

  created() {
    this.getCustomer("", "");
    this.getListOfWarehouseBranch("", "");
    this.getListOfMasterCurrency("", "");
  }

  download() {
    let params = {
      params: `${this.downloadParams.companyName},${this.downloadParams.branch},${this.downloadParams.customer},${this.downloadParams.currency},${this.downloadParams.date}`,
    } as RequestQueryParamsModel;
    if (this.downloadParams.search) params.search = this.downloadParams.search;
    this.loading.download = true;
    agingServices
      .getAgingDownload(params)
      .then(dataBlob => {
        if (dataBlob) {
          const url = window.URL.createObjectURL(new Blob([dataBlob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report_aging.xlsx"); //or any other extension
          link.click();
        }
      })
      .finally(() => (this.loading.download = false));
  }
  getListOfMasterCurrency(query, value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (query && value) params.search = query;
    return settingsServices
      .listOfMasterCurrency(params, "")
      .then(res => {
        this.dataListCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }
  clear() {
    this.form.resetFields();
  }
  getCustomer(query, value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `customer~true_AND_active~true`,
    };
    if (query && value) {
      params.search += query;
    }
    this.loadingCustomer = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataListCustomer = data.data;
      })
      .finally(() => (this.loadingCustomer = false));
  }

  assignSearch(key, value, operator): string {
    if (key === "branch" && value) return operator + `branchId~${value}`;
    else if (key === "customerName" && value)
      return operator + `customerId~${value}`;
    else if (key === "currency" && value)
      return operator + `currencyCode~${value}`;
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  getListOfWarehouseBranch(query, value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (query && value) params.search = query;
    this.loading.branch = true;
    return logisticServices
      .listWarehouseBranch(params, "")
      .then((res: any) => {
        res.data.map((dataObject, index) => (dataObject.key = index));
        this.dataListBranch = res.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  async findData(pagination): Promise<void> {
    try {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: pagination ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
          asOfDate: moment(res.asOfDate).format("YYYY-MM-DD"),
          search: ``,
        };
        if (res.branch)
          this.downloadParams.branch = this.dataListBranch.find(
            item => item.id === res.branch
          )?.name as string;
        if (res.customerName)
          this.downloadParams.customer = this.dataListCustomer
            .find(item => item.id == res.customerName)
            ?.firstName?.replaceAll(",", "") as string;
        if (res.currency) this.downloadParams.currency = res.currency;
        if (res.asOfDate)
          this.downloadParams.date = moment(res.asOfDate).format("YYYY-MM-DD");
        params.search += this.dynamicSearch(res);
        this.downloadParams.search = params.search as string;
        this.getReportAging(params);
      });
    } catch (error) {
      this.$message.error(Messages.PROCESS_FAIL);
    }
  }
  getReportAging(params) {
    this.loading.find = true;
    agingServices
      .getAgingReport(params)
      .then(res => {
        this.dataReport = res;
        this.dataSource = res.reportAgingListDTOList.map<TableRow>(
          (item, i) => ({
            ...item,
            key: i,
          })
        );
      })
      .finally(() => (this.loading.find = false));
  }
}
