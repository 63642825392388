var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_aging_report") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "label-col": { span: 6 },
            "wrapper-col": { span: 8 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.findData(false)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.branch.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.$t(_vm.formRules.branch.name),
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.branch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListOfWarehouseBranch(
                                "name~*" + value + "*",
                                value
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataListBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.customerName.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            allowClear: true,
                            loading: _vm.loading.customer,
                            "filter-option": false
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCustomer(
                                "_AND_firstName~*" +
                                  value +
                                  "*_OR_lastName~*" +
                                  value +
                                  "*",
                                value
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataListCustomer, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s("" + data.fullName) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.fullName) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.currency.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.currency.decorator,
                              expression: "formRules.currency.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.currency.name,
                            placeholder: _vm.$t(
                              _vm.formRules.currency.placeholder
                            ),
                            showSearch: true,
                            allowClear: true,
                            loading: _vm.loading.currency,
                            "filter-option": false
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListOfMasterCurrency(
                                "currencyCode~*" + value + "*",
                                value
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataListCurrency, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.currencyCode } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s("" + data.currencyCode) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s("" + data.currencyCode) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.asOfDate.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.asOfDate.decorator,
                            expression: "formRules.asOfDate.decorator"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          name: _vm.formRules.asOfDate.name,
                          placeholder: _vm.$t(
                            _vm.formRules.asOfDate.placeholder
                          ),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.clear } }, [
                        _vm._v(" " + _vm._s(this.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(this.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dataSource,
            columns: _vm.columnsTable,
            loading: _vm.loading.find,
            pagination: {
              showTotal: function(total) {
                return _vm.$t("lbl_total_items", { total: total })
              },
              showSizeChanger: true
            },
            size: "small",
            scroll: { x: 2500 }
          },
          scopedSlots: _vm._u([
            {
              key: "currency",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            },
            {
              key: "number",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
              }
            },
            {
              key: "date",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
              }
            },
            {
              key: "nullable",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            }
          ])
        },
        [
          _c(
            "a-descriptions",
            {
              attrs: { slot: "footer", size: "small", column: 5 },
              slot: "footer"
            },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_current_total") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.totalCurrent)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_grand_total"), span: 4 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.grandTotal)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_1_to_30_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding1)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_31_to_60_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding2)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_61_to_90_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding3)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_91_to_120_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding4)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_121_to_150_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding5)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_151_to_180_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding6)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_181_to_210_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding7)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_211_to_240_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding8)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_241_to_270_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding9)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_271_to_300_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding10)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_301_to_330_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding11)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_331_to_360_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding12)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_more_360_days") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataReport.totalOutstanding13)
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.loading.download,
                disabled: !_vm.dataSource.length
              },
              on: { click: _vm.download }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }